let GAPP = {
	/**
	 * 获取职业资格可搜索类型
	 */
	setPersonQuaTps() {
		$.ajax({
			url: XCONFIG.getCompleteUrl('/api/utils/personQuaTps'),
			dataType: 'jsonp',
			success: (response) => {
				$('.footPersonQuaTp').empty();
				for (let item of response.tps) {
					$(`<option value="${item.id}">${item.name}</option>`)
						.appendTo('.footPersonQuaTp');
				}
			}
		});
	},
	init() {
		this.setPersonQuaTps();
	},
	companyQuaSearch() {
		let data = {
			keyword: $('.footCompanyQuaKeyword').val(),
			tp: $('.footCompanyQuaTp').val(),
		};
		if (data.keyword === '') {
			return alert('请输入搜索关键词');
		}
		location.href = `${XCONFIG.getCompleteUrl('/company/qualification', 'm')}?keyword=${data.keyword}&tp=${data.tp}`;
	},
	companyRegistrySearch() {
		let data = {
			keyword: $('.footCompanyRegistryKeyword').val(),
			tp: $('.footCompanyRegistryTp').val(),
		};
		if (data.keyword === '') {
			return alert('请输入搜索关键词');
		}
		location.href = `${XCONFIG.getCompleteUrl('/company/registry', 'm')}?keyword=${data.keyword}&tp=${data.tp}`;
	},
	personQuaSearch() {
		let data = {
			keyword: $('.footPersonQuaKeyword').val(),
			ktp: $('.footPersonQuaKtp').val(),
			tp: $('.footPersonQuaTp').val(),
		};
		if (data.keyword === '') {
			return alert('请输入搜索关键词');
		}
		location.href = `${XCONFIG.getCompleteUrl('/person/qualification', 'm')}?keyword=${data.keyword}&ktp=${data.ktp}&tp=${data.tp}`;
	},
};

GAPP.init();

$('.footCompanyQuaSearch').click(() => {
	GAPP.companyQuaSearch();
});

$('.footCompanyQuaKeyword').keypress((e) => {
	if (e.keyCode === 13) {
		GAPP.companyQuaSearch();
	}
});

$('.footCompanyRegistrySearch').click(() => {
	GAPP.companyRegistrySearch();
});

$('.footCompanyRegistryKeyword').keypress((e) => {
	if (e.keyCode === 13) {
		GAPP.companyRegistrySearch();
	}
});

$('.footPersonQuaSearch').click(() => {
	GAPP.personQuaSearch();
});

$('.footPersonQuaKeyword').keypress((e) => {
	if (e.keyCode === 13) {
		GAPP.personQuaSearch();
	}
});
